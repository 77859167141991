import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';
import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createI18n } from 'vue-i18n';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import translations from './translations.json';
import * as Sentry from '@sentry/vue';
import VueTippy from 'vue-tippy';
import VueScreen from 'vue-screen';
import CookieConsentVue from './Plugins/CookieConsentVue';

// This is to make sure that all images are included in the build
import.meta.glob(['../assets/images/**']);

const appName =
    window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const i18n = createI18n({
    legacy: false,
    locale: navigator.language,
    fallbackLocale: 'en',
    messages: translations,
    missingWarn: false,
    fallbackWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
});

const clickOutsideDirective = {
    mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    beforeUnmount: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) });

        Sentry.init({
            app: vueApp,
            enabled: !!import.meta.env.VITE_SENTRY_VUE_DSN,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });

        return vueApp
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(i18n)
            .use(VueTippy, {
                directive: 'tippy', // => v-tippy
                component: 'tippy', // => <tippy/>
                componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
                defaultProps: {
                    theme: 'translucent',
                    animation: 'shift-toward-subtle',
                },
            })
            .use(VueScreen, 'tailwind')
            .use(CookieConsentVue, {
                root: '#app',
                categories: {
                    necessary: {
                        enabled: true, // this category is enabled by default
                        readOnly: true, // this category cannot be disabled
                    },
                    analytics: {
                        enabled: false,
                    },
                },

                language: {
                    default: 'en',
                    translations: {
                        en: {
                            consentModal: {
                                title: 'We Use Cookies',
                                description:
                                    "We're using cookies and third-party cookies to provide you with the best possible service.",
                                acceptAllBtn: 'Accept All',
                                acceptNecessaryBtn: 'Reject All',
                                showPreferencesBtn:
                                    'Manage Individual Preferences',
                            },
                            preferencesModal: {
                                title: 'Manage Cookie Preferences',
                                acceptAllBtn: 'Accept All',
                                acceptNecessaryBtn: 'Reject All',
                                savePreferencesBtn: 'Accept current selection',
                                closeIconLabel: 'Close modal',
                                sections: [
                                    {
                                        title: 'Technically Necessary Cookies',
                                        description:
                                            'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                                        linkedCategory: 'necessary',
                                    },
                                    {
                                        title: 'Performance and Analytics',
                                        description:
                                            'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                        linkedCategory: 'analytics',
                                    },
                                    {
                                        title: 'What are cookies?',
                                        description:
                                            "These are small text files that are stored on your device via your browser. They store data about your visit to our website and thereby enhance our user-friendliness: for example, they remember your language selection and your browser login details, so you don't have to enter them again. If you agree to our use of cookies, you consent to this and allow us to share this data with third parties, such as our marketing partners. This may include processing your data in the USA. If you do not agree, we will naturally limit ourselves to technically necessary cookies.",
                                    },
                                ],
                            },
                        },
                    },
                },
            })
            .directive('click-outside', clickOutsideDirective)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
